










































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import { delFangan, getFanganList } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private data: any = [];
  private options: any = [
    "经络数据采集报告",
    "中医体质辨识",
    "老年人体质辨识",
    "中医健康状态智能测评",
  ];
  private openAdd() {
    this.$router.push({
      path: "/main/manage/fangan/edit",
    });
  }
  private openEdit(item: any) {
    this.$router.push({
      path: "/main/manage/fangan/edit",
      query: {
        data: JSON.stringify(item),
      },
    });
  }
  private read(item: any) {}
  private del(item: any) {
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      const params: any = {
        data: {
          id: item.id,
        },
      };
      delFangan(this, params).then((data: any) => {
        this.$message.success("删除成功");
        this.getList();
      });
    });
  }
  /**
   * @description 获得舌图图片列表
   */
  getList() {
    const params = {
      params: {
        project_name: this.filters.search,
        ceping_name: this.filters.ceping,
      },
    };
    localStorage.setItem("topicFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getFanganList(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res.data;
        this.filters.total_page = res.total_page;
        this.filters.total_count = res.total_count;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.sort_field = "更新时间";
    const d = localStorage.getItem("topicFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
